import { Box } from '@mantine/core';
import LogoPrimary from '@/../public/ArcanisLabsLogoPrimary.png';
import Image from 'next/image';
import { ArrowRightIcon } from '@heroicons/react/24/outline';

export const MobileTitleBox = () => {
  const handleClick = () => {
    window.open('https://arcaniscapital.com', '_blank');
  };
  return (
    <Box className='overflow-visible flex w-full h-auto text-secondary-500 dark:text-lightBlue-200'>
      <Box className='flex flex-col w-full h-full justify-center items-center gap-8'>
        <Image
          priority
          src={LogoPrimary}
          alt='Arcanis Capital Logo'
          className='h-6 w-auto object-cover '
          quality={100}
        />
        <p
          className='font-montserrat font-extrabold tracking-wide text-center uppercase'
          style={{
            fontSize: 'calc(16px + 6vw)',
            lineHeight: '1.2',
            margin: '0',
          }}
        >
          VENTURE CAPITAL <br /> INTELLIGENCE REDEFINED
        </p>
        <p className='text-secondary-500/90 dark:text-lightBlue-200/90 text-xl text-center font-montserrat font-bold tracking-wide mx-7'>
          Big data VC research lab <br /> and issuer of independent <br />
          VC growth indexes
        </p>
        <button
          onClick={handleClick}
          className='flex items-center text-gray-50 bg-gradient-to-r from-primary-500 to-primary-600 rounded-lg px-6 py-2 hover:from-primary-300 hover:to-primary-400 hover:text-gray-50 font-semibold'
        >
          <p className='mr-2'>Main Website</p>
          <ArrowRightIcon className='w-4 h-4' />
        </button>
      </Box>
    </Box>
  );
};
