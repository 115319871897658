'use client';

import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Box } from '@mantine/core';
import Image from 'next/image';
import LogoPrimary from '@/../public/ArcanisLabsLogoPrimary.png';

export const TitleBox = () => {
  const handleClick = () => {
    window.open('https://arcaniscapital.com', '_blank');
  };

  return (
    <Box className='overflow-hidden flex w-full h-full'>
      <Box className='flex flex-col w-full h-full justify-center items-center'>
        <Box className='flex flex-col w-full justify-start items-start '>
        <Image
          priority
          src={LogoPrimary}
          alt='Arcanis Capital Logo'
          className='h-8 w-auto object-cover '
          quality={100}
        />
          <p className='dark:text-lightBlue-200 text-secondary-500 mt-6 md:text-[4rem] lg:text-[5rem] xl:text-[5rem] font-montserrat font-extrabold tracking-wide text-left'>
            VENTURE CAPITAL
          </p>
          <p className='text-left text-secondary-500/90 dark:text-lightBlue-200/80 -mt-4 md:-mt-4 w-full md:text-[2.5rem] lg:text-[2.5rem] xl:text-[3rem] font-montserrat font-extrabold tracking-wide leading-snug'>
            INTELLIGENCE REDEFINED
          </p>
          <p className='text-secondary-500 dark:text-lightBlue-500 md:text-xl lg:text-xl text-left tracking-wide leading-snug mt-8'>
            Big data VC research lab and issuer
            <br />
            of independent VC growth indexes
          </p>
          <button
            onClick={handleClick}
            className='flex mt-12 items-center text-gray-50 bg-gradient-to-r from-primary-500 to-primary-600 rounded-lg px-6 py-2 hover:from-primary-300 hover:to-primary-400 hover:text-gray-50 font-semibold'
          >
            
            <p className='mr-2'>Main Website</p>
            <ArrowRightIcon className='w-4 h-4'/>
          </button>
        </Box>
      </Box>
    </Box>
  );
};
