'use client';

import { useMediaQuery } from '@mantine/hooks';
import { Box } from '@mantine/core';
import Image from 'next/image';
import { TitleBox } from './TitleBox/TitleBox';
import { MobileTitleBox } from './TitleBox/MobileTitleBox';
import HeroImageChartNarrowSVGLG from '@/../public/bg/HeroImageChartNarrowLG.svg';
import MobileBG from '@/../public/bg/MobileBG2.png';
import { useEffect, useState } from 'react';

export const HeroSection = () => {
  const [isMounted, setIsMounted] = useState(false);
  const isOnLargeScreen = useMediaQuery('(min-width: 1060px)');

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  }

  return (
    <Box
      className={
        isOnLargeScreen
          ? `hero-section-background`
          : `mobile-hero-section-background`
      }
      id='home'
      style={{
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
        zIndex: 50,
      }}
    >
      <Box
        id='heroimage'
        className='hidden md:flex absolute w-2/3 h-full right-0 justify-end items-center'
      >
        <HeroImageChartNarrowSVGLG />
      </Box>
      <Box
        className='h-full'
        style={{
          position: 'relative',
          zIndex: 20,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Box className='flex overflow-visible xxs:mx-[20px] md:mx-[120px] w-full justify-left items-start h-full xxs:max-md:items-center xxs:max-md:mt-0'>
          <Box className='flex flex-col relative w-full h-full justify-start xxs:max-md:justify-center'>
            {isOnLargeScreen ? <TitleBox /> : <MobileTitleBox />}
            {/* <TitleBox /> */}
            <Image
              src={MobileBG}
              alt='Chart Hero Image'
              className='md:hidden'
              fill
              quality={100}
              style={{
                objectFit: 'cover',
                overflow: 'visible',
                opacity: 1,
                zIndex: -1,
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
